import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RadioGroup from './event_setup/radio_group'
import { GlgSelect } from 'SharedComponents'
import _ from 'lodash'
import InputForm from './event_setup/input_form'

const internationalize = (...args) => !window.I18n ? '' : window.I18n.t(...args)

class EventSetupOtherOptions extends Component {
  constructor(props){
    super(props)
    this.state = {
      dataChanged: false,
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.content.data !== prevProps.content.data && this.props.content.dataChanged) || this.state.dataChanged) { 
      this.props.save()
      this.setState({dataChanged: false})
    }
  }

  completeForm(value) {
    this.props.setData([ 'data', 'league_name', 'value' ], value)
    this.setState({dataChanged: true})
  }

  render() {
    const { title, data } = this.props.content
    const setter = (keys) => (value) => this.props.setData([ 'data' ].concat(keys), value)

    return (
      <div className='widget-content-default widget-event-setup-other-options'>
        <div className='title'>
          { title }
        </div>

        <div className='content flex-list-layout'>
          <ul>
            <li className='league_name_container'>
              <b> { internationalize('leagues.bootstrap_tabs.tabs1.product.name', { product: internationalize(`${data.product}_name`) }) } </b>
              <div>
                <label>
                  { internationalize('leagues.bootstrap_tabs.tabs1.product.description', { product_title: _.capitalize(internationalize(`${data.product}_name`)) }) }
                </label>
                <InputForm
                  className='form-control'
                  value={data.league_name.value}
                  onEditCompleted={(name) => name !== data.league_name.value && this.completeForm(name)}
                />
              </div>
            </li>

            {
              'rounds' in data && 
                (() => {
                  const rounds = data.rounds
                  const selectedRound = rounds.select_round.selected
                  const roundData = rounds.data[selectedRound]

                  return (
                    <>
                      <li className='select_round_container'>
                        <GlgSelect
                          items={ rounds.select_round.options }
                          value={ rounds.select_round.selected }
                          onChange={ round => {
                            this.props.setData([ 'data', 'rounds', 'select_round', 'selected' ], round, { updateDataChanged: false })
                          }}
                          usePortal={ true }
                          menuShouldBlockScroll={ true }
                        />
                      </li>

                      {
                        !roundData.hide_qualifying_option &&
                          <li>
                            <RadioGroup
                              id={`score_posting_rounds_${selectedRound}`}
                              label={internationalize('controllers.widgets.event_setup.score_posting_rounds')}
                              choices={[ {
                                label: internationalize('rounds.tabs.tabs3.qualifying_option', {terminology: roundData.qualifying_terminology}),
                                value: true,
                                enabled: roundData.score_posting_enabled,
                                tooltip: roundData.score_posting_tooltip,
                              }, {
                                label: internationalize('rounds.tabs.tabs3.non_qualifying_option', {terminology: roundData.qualifying_terminology}),
                                value: false,
                                enabled: roundData.score_posting_enabled,
                                tooltip: roundData.score_posting_tooltip,
                              } ]}
                              selected={ roundData.qualifying }
                              setOption={ (v) => {
                                this.props.setData([ 'data', 'rounds', 'data', selectedRound, 'qualifying' ], v === 'true')
                              }}
                              vertical={true}
                            />
                          </li>
                      }

                      {
                        roundData.qualifying &&
                          <li>
                            <RadioGroup
                              id={`automatically_post_scores_${selectedRound}`}
                              label={internationalize('rounds.tabs.tabs3.automatically_post_scores_question')}
                              choices={[ {
                                label: internationalize('rounds.tabs.tabs3.tag_yes'),
                                  value: true,
                              }, {
                                label: internationalize('rounds.tabs.tabs3.tag_no'),
                                value: false,
                              } ]}
                              selected={ roundData.automatically_post_scores }
                              setOption={ (v) => {
                                this.props.setData([ 'data', 'rounds', 'data', selectedRound, 'automatically_post_scores' ], v === 'true')
                              }}
                              vertical={true}
                            />
                          </li>
                      }
                    </>
                  )
                })()
            }

            {
              data['default_attendance_status'] &&
                <li key='default_attendance_status'>
                  <RadioGroup
                    id='default_attendance_status'
                    {...data['default_attendance_status']}
                    setOption={ setter([ 'default_attendance_status', 'selected' ])}
                    vertical={true}
                  />
                </li>
            }

            {
              data['default_gender'] &&
                <li key='default_gender' className='default_gender'>
                  <RadioGroup
                    id='default_gender'
                    {...data['default_gender']}
                    setOption={ setter([ 'default_gender', 'selected' ])}
                    vertical={true}
                  />
                </li>
            }
          </ul>
        </div>
      </div>
    )
  }
}

EventSetupOtherOptions.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  save: PropTypes.func,
}

EventSetupOtherOptions.defaultProps = {
  content: {
    title: 'OTHER OPTIONS',
    data: {},
  },
}

export default EventSetupOtherOptions
